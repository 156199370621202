/*
Theme Name: cashseizurelaw.com
Description: Theme for cashseizurelaw.com
Author: TrafficWorx
Template: twentyeleven
*/
/* WORDPRESS ---------- */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Shadows.
 */
/**
 * Editor widths.
 */
/**
 * Block & Editor UI.
 */
/**
 * Block paddings.
 */
/**
 * React Native specific.
 * These variables do not appear to be used anywhere else.
 */
.wp-block-table {
  overflow-x: auto;
}
.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}
.wp-block-table thead {
  border-bottom: 3px solid;
}
.wp-block-table tfoot {
  border-top: 3px solid;
}
.wp-block-table td,
.wp-block-table th {
  border: 1px solid;
  padding: 0.5em;
}
.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}
.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
  word-break: break-word;
}
.wp-block-table.alignleft, .wp-block-table.aligncenter, .wp-block-table.alignright {
  display: table;
  width: auto;
}
.wp-block-table.alignleft td,
.wp-block-table.alignleft th, .wp-block-table.aligncenter td,
.wp-block-table.aligncenter th, .wp-block-table.alignright td,
.wp-block-table.alignright th {
  word-break: break-word;
}
.wp-block-table .has-subtle-light-gray-background-color {
  background-color: #f3f4f5;
}
.wp-block-table .has-subtle-pale-green-background-color {
  background-color: #e9fbe5;
}
.wp-block-table .has-subtle-pale-blue-background-color {
  background-color: #e7f5fe;
}
.wp-block-table .has-subtle-pale-pink-background-color {
  background-color: #fcf0ef;
}
.wp-block-table.is-style-stripes {
  border-spacing: 0;
  border-collapse: inherit;
  background-color: transparent;
  border-bottom: 1px solid #f0f0f0;
}
.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}
.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
  background-color: #f3f4f5;
}
.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
  background-color: #e9fbe5;
}
.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
  background-color: #e7f5fe;
}
.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
  background-color: #fcf0ef;
}
.wp-block-table.is-style-stripes th,
.wp-block-table.is-style-stripes td {
  border-color: transparent;
}
.wp-block-table .has-border-color > *,
.wp-block-table .has-border-color tr,
.wp-block-table .has-border-color th,
.wp-block-table .has-border-color td {
  border-color: inherit;
}
.wp-block-table table[style*=border-top-color] > *,
.wp-block-table table[style*=border-top-color] tr:first-child {
  border-top-color: inherit;
}
.wp-block-table table[style*=border-top-color] > * th,
.wp-block-table table[style*=border-top-color] > * td,
.wp-block-table table[style*=border-top-color] tr:first-child th,
.wp-block-table table[style*=border-top-color] tr:first-child td {
  border-top-color: inherit;
}
.wp-block-table table[style*=border-top-color] tr:not(:first-child) {
  border-top-color: currentColor;
}
.wp-block-table table[style*=border-right-color] > *,
.wp-block-table table[style*=border-right-color] tr,
.wp-block-table table[style*=border-right-color] th,
.wp-block-table table[style*=border-right-color] td:last-child {
  border-right-color: inherit;
}
.wp-block-table table[style*=border-bottom-color] > *,
.wp-block-table table[style*=border-bottom-color] tr:last-child {
  border-bottom-color: inherit;
}
.wp-block-table table[style*=border-bottom-color] > * th,
.wp-block-table table[style*=border-bottom-color] > * td,
.wp-block-table table[style*=border-bottom-color] tr:last-child th,
.wp-block-table table[style*=border-bottom-color] tr:last-child td {
  border-bottom-color: inherit;
}
.wp-block-table table[style*=border-bottom-color] tr:not(:last-child) {
  border-bottom-color: currentColor;
}
.wp-block-table table[style*=border-left-color] > *,
.wp-block-table table[style*=border-left-color] tr,
.wp-block-table table[style*=border-left-color] th,
.wp-block-table table[style*=border-left-color] td:first-child {
  border-left-color: inherit;
}
.wp-block-table table[style*=border-style] > *,
.wp-block-table table[style*=border-style] tr,
.wp-block-table table[style*=border-style] th,
.wp-block-table table[style*=border-style] td {
  border-style: inherit;
}
.wp-block-table table[style*=border-width] > *,
.wp-block-table table[style*=border-width] tr,
.wp-block-table table[style*=border-width] th,
.wp-block-table table[style*=border-width] td {
  border-width: inherit;
  border-style: inherit;
}

/* STYLES ---------- */
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 112.5%;
  line-height: 1.5;
  background-color: #e6e6e6;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
}

body img {
  max-width: 100%;
}

/* TYPEOGRAPHY ---------- */
h1 {
  font-family: "Oswald", sans-serif;
  font-size: 36px;
  line-height: 1.4;
  font-weight: 600;
  color: #242c65;
  margin: 26px 0 24px 0;
}

h2 {
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  line-height: 1.4;
  font-weight: 600;
  color: #242c65;
  margin: 26px 0 24px 0;
}

h3 {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 600;
  color: #242c65;
  margin: 26px 0 24px 0;
}

a {
  color: #3a47a4;
  text-decoration: none;
}

/* unvisited link */
a:link {
  color: #3a47a4;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #3a47a4;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: #242c65;
  text-decoration: underline;
}

/* selected link */
a:active {
  color: #3a47a4;
  text-decoration: none;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio], input[type=checkbox] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=time], input[type=datetime-local], input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

/* BUTTONS ---------- */
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  background-image: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary, .btn-primary:visited, .btn-primary:link {
  color: #fff;
  background-color: #242c65;
  border-color: #242c65;
}

.btn-primary:hover {
  color: #fff;
  background-color: #3a47a4;
  border-color: #242c65;
}

.btn-primary:focus, .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #242c65;
  border-color: #242c65;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #333;
  background-color: #ffc51b;
  border-color: #ffc51b;
}

.btn-secondary:hover {
  color: #333;
  background-color: #ffd24e;
  border-color: #ffc51b;
}

.btn-secondary:focus, .btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #ffc51b;
  border-color: #ffc51b;
}

.btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* FORMS ---------- */
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #999;
  background-color: #ececec;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.col-form-legend {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-plaintext {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control, .input-group-sm > .form-control-plaintext.input-group-addon, .input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control, .input-group-lg > .form-control-plaintext.input-group-addon, .input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), .input-group-sm > select.input-group-addon:not([size]):not([multiple]), .input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), .input-group-lg > select.input-group-addon:not([size]):not([multiple]), .input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}

.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.valid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated.custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated.custom-select:valid:focus, .custom-select.is-valid:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated.custom-select:valid ~ .valid-feedback, .was-validated.custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated.custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated.custom-select:invalid ~ .invalid-feedback, .was-validated.custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-flow: row wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

/* ASSISTIVE TEXT ---------- */
.assistive-text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

/* WRAPPERS ---------- */
.container {
  width: 100%;
  float: left;
  position: relative;
}

.wrapper {
  max-width: 1140px;
  padding-left: 65px;
  padding-right: 65px;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/* HEADER ---------- */
#topWrapperContainer {
  background-color: #0e1126;
  width: 100%;
  z-index: 1000;
  -webkit-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  position: sticky;
  top: 0;
}
@media screen and (min-width: 768px) {
  #topWrapperContainer.scrolled {
    background-color: #ffd24e;
  }
}

#topWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 14px;
}

#topWrapper .sitetitle {
  color: #FFF;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  -webkit-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

#topWrapper .phone {
  -webkit-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  text-align: right;
}
#topWrapper .phone .phone-us {
  color: #FFF;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}
#topWrapper .phone .phone-int {
  color: #FFF;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}
#topWrapper .phone a {
  color: #FFF;
}
#topWrapper .phone a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  #topWrapperContainer.scrolled #topWrapper .sitetitle, #topWrapperContainer.scrolled #topWrapper .phone-us, #topWrapperContainer.scrolled #topWrapper .phone-int, #topWrapperContainer.scrolled #topWrapper a {
    color: #0e1126;
  }
}
/* NAVIGATION ---------- */
#navigation {
  margin-bottom: 3px;
  background: #333;
  background-image: -webkit-linear-gradient(#555555 0%, #333333 100%);
  background-image: -o-linear-gradient(#555555 0%, #333333 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#555555), to(#333333));
  background-image: linear-gradient(#555555 0%, #333333 100%);
  -webkit-box-shadow: 0px 3px 5px #888888;
  box-shadow: 0px 3px 5px #888888;
}
@media screen and (min-width: 768px) {
  #navigation {
    margin-top: 0px;
  }
}

#navigation .wrapper, #footer .wrapper, #topWrapper {
  background-color: transparent;
}

#access {
  display: block;
  margin: auto;
  text-align: center;
}

#access ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#access ul li:first-child {
  width: 90px;
}

.home #access ul li:first-child {
  width: 90px;
}

#access li {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 200px;
  flex: 1 0 200px;
  position: relative;
}

#access a {
  display: block;
  font-weight: 600;
  color: #fff;
  line-height: 1.5;
  padding: 12px 12px;
  text-decoration: none;
  -webkit-transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}

#access li:hover > a, #access ul ul :hover > a, #access a:focus {
  color: #fff;
  background: #242c65;
  background-image: -webkit-linear-gradient(#242c65 0%, #0e1126 100%);
  background-image: -o-linear-gradient(#242c65 0%, #0e1126 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#242c65), to(#0e1126));
  background-image: linear-gradient(#242c65 0%, #0e1126 100%);
}

#access li:hover > a, #access ul li:hover > ul {
  display: block;
}

#access .current-menu-item > a, #access .current-menu-ancestor > a, #access .current_page_item > a, #access .current_page_ancestor > a {
  color: #fff;
  background: #242c65;
  background-image: -webkit-linear-gradient(#242c65 0%, #0e1126 100%);
  background-image: -o-linear-gradient(#242c65 0%, #0e1126 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#242c65), to(#0e1126));
  background-image: linear-gradient(#242c65 0%, #0e1126 100%);
}

#mobile-menu-toggle {
  display: none;
  position: absolute;
  width: 48px;
  height: 48px;
  top: 16px;
  right: 16px;
  font-size: 22px;
  color: #FFF;
  background-color: #0e1126;
  border: 1px solid #fff;
  border-radius: 4px;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

@media screen and (min-width: 768px) {
  #topWrapperContainer.scrolled #mobile-menu-toggle {
    color: #0e1126;
    background-color: #ffd24e;
    border: 1px solid #0e1126;
  }
}
#mobile-menu-toggle:hover {
  color: #0e1126;
  background-color: #fff;
}

@media screen and (min-width: 768px) {
  #topWrapperContainer.scrolled #mobile-menu-toggle:hover {
    color: #ffd24e;
    background-color: #0e1126;
  }
}
.mobile_phone {
  display: none;
}

#access {
  -webkit-transition: top 0.2s ease-in-out;
  -o-transition: top 0.2s ease-in-out;
  transition: top 0.2s ease-in-out;
}

/* BREADCRUMB ---------- */
.breadcrumb {
  width: 100%;
  padding: 6px 12px;
  background: #ececec;
  font-size: 14px;
  margin: 0 0 6px 0;
}

/* LEAD ---------- */
#lead div.wrapper {
  padding-top: 12px;
}

/* VIDEO & FORM ---------- */
#lead div.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding: 24px;
  border-bottom: solid 1px #ececec;
  background: url("images/bg.png") #242c65;
}

#cta {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 45%;
  flex: 1 0 45%;
  padding-right: 24px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#cta div {
  display: block;
  position: relative;
}

#cta span {
  display: block;
  position: relative;
  text-shadow: 0 3px 3px #000;
  color: #fff;
}

#cta span.el1 {
  font-size: 65px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

#cta span.el2 {
  font-size: 43px;
  color: #f4e260;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
}

#cta .shield {
  padding-left: 130px;
  margin: 0 auto;
  margin-bottom: 10px;
}

#cta .shield a {
  display: block;
  width: 130px;
  height: 108px;
  position: absolute;
  left: 0;
  top: -5px;
  background: url("images/shield.png") no-repeat;
  background-size: cover;
  text-indent: -1000em;
}

#cta span.el3 {
  font-size: 52px;
  color: #f4e260;
  text-align: left;
}

#cta span.el4 {
  text-align: left;
  font-size: 43px;
}

.banner_vid {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 55%;
  flex: 1 0 55%;
  position: relative;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16: 9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#frmWrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  position: relative;
  padding: 36px 0;
}

h2.form-header {
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
}

h2.form-header:before, h2.form-header:after {
  content: "";
  display: inline-block;
  height: 6px;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  margin-top: -6px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

h2.form-header:before {
  right: 24px;
  margin-left: -50%;
}

h2.form-header:after {
  left: 24px;
  margin-right: -50%;
}

#frmWrapper form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#frmWrapper div.form-group {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32.5%;
  flex: 0 0 32.5%;
}

#frmWrapper div.form-group:nth-of-type(4) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.25%;
  flex: 0 0 66.25%;
}

#frmWrapper label {
  display: none;
}

#privacyIcon {
  color: #a1a9b3;
  font-size: 12px;
  margin-bottom: 12px;
  position: relative;
  padding-left: 18px;
  width: 100%;
}

#privacyIcon img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-wrapper {
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.btn-wrapper:before, .btn-wrapper:after {
  content: "";
  display: inline-block;
  height: 6px;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  margin-top: -6px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.btn-wrapper:before {
  right: 24px;
  margin-left: -50%;
}

.btn-wrapper:after {
  left: 24px;
  margin-right: -50%;
}

.error {
  border: 1px red solid !important;
}

#frmResponseWrapper h3.success {
  text-align: center;
  color: green;
}

#frmResponseWrapper ul#form-errors {
  color: red;
}

/* CONTENT ---------- */
#content.container .wrapper {
  padding-bottom: 65px;
}

#primary {
  width: calc(67% - 48px);
  margin-right: 48px;
  float: left;
}

#primary.full-width {
  width: 100%;
  margin-right: 0;
  float: left;
}

/* SIDEBAR ---------- */
#secondary {
  width: 33%;
  float: left;
}

#secondary ul.xoxo {
  list-style: none;
  margin: 0;
  border: solid 1px #ccc;
  background: url("images/bg.png");
  padding: 12px;
}

#secondary ul.xoxo aside.widget {
  background: #fff;
  border: solid 1px #ccc;
  padding: 12px;
  font-size: 14px;
  margin-bottom: 12px;
}

#secondary ul.xoxo aside.widget:last-child {
  margin-bottom: 0px;
}

#secondary ul.xoxo aside.widget h3.widget-title {
  background: url("images/bg.png") #242c65;
  color: #fff;
  padding: 12px;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px;
}

#secondary ul.xoxo .widget_recent_entries ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: solid 1px #ccc;
}

#secondary ul.xoxo .widget_recent_entries ul li {
  border-bottom: solid 1px #ccc;
}

#secondary ul.xoxo .widget_recent_entries ul li a {
  display: block;
  width: 100%;
  padding: 12px 12px;
  text-decoration: none;
  color: #333;
  -webkit-transition: background 0.2s ease-in-out, padding-left 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out, padding-left 0.2s ease-in-out;
  transition: background 0.2s ease-in-out, padding-left 0.2s ease-in-out;
}

#secondary ul.xoxo .widget_recent_entries ul li a:hover {
  background: #ececec;
  padding-left: 24px;
}

/* AGENCY LOGOS ---------- */
.agency-logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 48px 0;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.agency-logos .logo {
  display: block;
  margin: 12px;
  width: 140px;
  height: 140px;
  background: #ccc;
}

/* FOOTER ---------- */
#footer {
  font-size: 14px;
  margin-top: 100px;
  background: url("images/bg.png") #1e1e1e;
}

#colophon {
  color: #FFF;
  padding: 24px 0;
  line-height: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#colophon a {
  color: #FFF;
}

#colophon .name {
  font-size: 28px;
  margin-bottom: 0;
}

#colophon h4 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 12px;
}

#colophon .colLeft {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
  padding-right: 24px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

#colophon .colLeft ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#colophon .colLeft ul li {
  margin: 0 12px 0 0;
  padding: 0;
  display: inline-block;
}

.copyright {
  font-size: 14px;
}

#colophon .colMiddle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

#colophon .colRight {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
  padding-top: 12px;
  padding-left: 24px;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.homepage-intro .textwidget {
  padding-right: 8px;
}

#the-video {
  margin-top: 20px;
  margin-right: 20px;
  float: right;
}

.video_item {
  float: left;
  width: 170px !important;
  height: 130px;
  margin: 0px 8px 0px 0px !important;
  background-color: #000;
}

.video_wrapper {
  margin: auto;
  text-align: center;
}

.video_wrapper .text {
  padding-top: 0px;
  margin-left: 185px;
  padding-left: 4px;
}

#shield {
  float: left;
  padding-top: 190px;
  padding-left: 20px;
}

#shield_right {
  float: right;
  padding-right: 40px;
  padding-top: 12px;
}

.seals {
  text-align: center;
}

.rightNav .strippedBGPurple h2 {
  text-transform: uppercase;
}

.bodyContent div.vjs-big-play-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px !important;
  height: 17px !important;
  margin: -8px 0 0 -7px;
  background: url("http: //vjs.zencdn.net/c/video-js.png") 0 0 !important;
}

.bodyContent div.vjs-big-play-button {
  display: block;
  z-index: 2;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  width: 2em !important;
  height: 2em !important;
  margin: -10px 0 0 -10px;
  text-align: center;
  vertical-align: center;
  cursor: pointer !important;
  border: 0.3em solid #fff;
  opacity: 0.95;
  border-radius: 5px !important;
  background: #454545;
  background: -webkit-gradient(linear, 0% 0, 0% 100%, color-stop(0%, #454545), color-stop(50%, #232323), color-stop(50%, #161616), color-stop(100%, #3f3f3f));
  background: -webkit-linear-gradient(top, #454545 0, #232323 50%, #161616 50%, #3f3f3f 100%);
  background: -o-linear-gradient(top, #454545 0, #232323 50%, #161616 50%, #3f3f3f 100%);
  background: -ms-linear-gradient(top, #454545 0, #232323 50%, #161616 50%, #3f3f3f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #454545), color-stop(50%, #232323), color-stop(50%, #161616), to(#3f3f3f));
  background: linear-gradient(top, #454545 0, #232323 50%, #161616 50%, #3f3f3f 100%);
  -webkit-box-shadow: 4px 4px 8px #000;
  box-shadow: 4px 4px 8px #000;
}

article {
  margin-bottom: 4px !important;
}

article .hentry {
  margin-bottom: 4px !important;
}

article .entry-content {
  overflow-wrap: break-word;
  min-width: 0;
}

.archive article {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

#headerVcard {
  float: right;
}

.video_item2 {
  width: 320px !important;
  height: 280px;
  margin: auto;
  background-color: #000;
}

.embed_video {
  color: #848486;
  border-radius: 12px;
  background: #FFF;
  /* Show a solid color for older browsers */
  border: 1px #CCC solid;
  margin: auto;
}

.embed_video .hdr {
  border-radius: 12px 12px 0px 0px;
  padding: 8px;
  font-weight: bold;
  color: #333;
  background: #f9f9f9;
  /* Show a solid color for older browsers */
  background: -o-linear-gradient(#f9f9f9, #e5e5e5);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#f9f9f9), to(#e5e5e5));
  /* Older webkit syntax */
  background: -webkit-linear-gradient(#f9f9f9, #e5e5e5);
}

.subtitle {
  color: #46a1d8;
  padding-left: 8px;
}

.rightNav .headline {
  color: #2d4173;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
}

.purple {
  color: #2d4173 !important;
}

.home .rightNav .cases {
  margin-top: 2px;
  padding-top: 0px;
}

.home .rightNav .case strong {
  color: #242c65;
}

.home .rightNav .month {
  padding: 6px 8px 0px 8px;
  font-size: 16px;
  font-weight: bold;
}

.home .rightNav ul li.case:first-child {
  padding-top: 4px;
}

.home .rightNav .month {
  padding: 6px 8px 0px 8px;
  font-size: 16px;
  font-weight: bold;
}

.home .leftNav h2 {
  margin-top: 12px;
}

.home .leftNav iframe {
  display: block;
  margin: 12px 0px;
}

.postthumbs td {
  padding: 10px 4px 0px 4px;
  border-bottom: 1px #EEE solid;
  vertical-align: top;
}

.postthumbs td img {
  padding: 2px;
  border: 1px #CCC solid;
}

.postthumbs .post_info {
  vertical-align: top;
  padding-top: 8px;
}

.postthumbs .post_info .post_title {
  font-size: 16px;
  margin-bottom: 8px;
}

.postthumbs .post_info .postmetadata {
  font-size: 12px;
}

.postthumbs .post_info .postmetadata a {
  font-weight: normal;
}

.related_posts_wrapper .related_article .headline {
  font-weight: 700px;
  font-size: 18px;
}

.related_article {
  font-size: 12px;
  border: 0px #000 solid;
  overflow: hidden;
  margin-bottom: 12px;
  padding: 0px 8px 10px 10px;
}

.related_thumb {
  vertical-align: middle;
}

.related_thumb img {
  margin-right: 4px;
  width: 60px;
}

.excerpt_image {
  margin-left: 80px;
}

#sharebar {
  z-index: 10000 !important;
}

.postthumbs td {
  padding: 2px;
}

.postthumbs tr:nth-child(even) {
  background-color: #fdfee9;
  border-top: 1px #d5d5d0 solid;
}

.postthumbs tr:nth-child(odd) {
  background-color: #FFF;
  border-top: 1px #d5d5d0 solid;
}

/*.postthumbs tr:last-child {
	border-bottom: 1px #d5d5d0 solid;
}
*/
.postthumbs .post_info {
  padding: 8px 2px 2px 2px;
}

.postthumbs .post_info a.post_title {
  text-decoration: none;
}

td.post_thumbnail {
  text-align: center;
  padding: 2px 8px 2px 0px;
  vertical-align: middle;
}

td.post_info p {
  margin-bottom: 6px;
}

.postthumbs img {
  border: 1px #d5d5d0 solid;
  padding: 4px;
}

.postthumbs .post_info a.post_title:hover {
  text-decoration: underline;
}

.postthumbs .post_info .postmetadata a {
  color: #860002;
  text-decoration: none;
}

/* -- Homepage: Intro -- */
.home .homepage-intro {
  margin-bottom: 25px;
}

.home .homepage-intro h1 {
  text-align: center;
}

.homepage-intro .textwidget {
  text-align: center;
}

/* -- Homepage: Need Assistance? -- */
#need-assistance {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

#need-assistance h2 {
  font-size: 32px;
  margin-bottom: 20px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#need-assistance h2 .consumer-caution {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-right: 36px;
}

/* -- Homepage: FAQ -- */
.faq {
  text-align: left;
  padding-left: 48px;
}

.faq p {
  position: relative;
}

.faq span.question, .faq span.answer {
  position: absolute;
  top: 0;
  left: -24px;
  font-family: "Oswald", sans-serif;
  color: #242c65;
}

/* -- Homepage: Featured Content -- */
#featured-content h3 {
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}

#featured-content h3:before, #featured-content h3:after {
  content: "";
  display: inline-block;
  height: 6px;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  margin-top: -6px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

#featured-content h3:before {
  right: 24px;
  margin-left: -50%;
}

#featured-content h3:after {
  left: 24px;
  margin-right: -50%;
}

#featured-content p {
  font-size: 16px;
}

#featured-content .featured-list .widget {
  margin-bottom: 20px;
}

#featured-content ul.menu {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

#featured-content ul.menu li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
  font-size: 16px;
  margin: 0;
  padding: 10px 20px 72px 20px;
  position: relative;
}

#featured-content ul.menu li a.title, #featured-content ul.menu li a.title:link, #featured-content ul.menu li a.title:hover, #featured-content ul.menu li a.title:active, #featured-content ul.menu li a.title:visited {
  display: block;
  margin-bottom: 15px;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  color: #242c65;
  text-decoration: none;
  line-height: 1.2;
}

#featured-content ul.menu li .desc {
  max-height: 105px;
  height: 105px;
  overflow: hidden;
  font-size: 14px;
}

#featured-content ul.menu li a.btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
}

/*--------------------------------------------------------------Mobile Viewports;
Max 1145px-------------------------------------------------------------- */
@media (max-width: 1145px) {
  /* ----------------------- Footer ----------------------- */
}
/*--------------------------------------------------------------Mobile Viewports;
Max 1190px-------------------------------------------------------------- */
@media (max-width: 1190px) {
  .wrapper, #colophon {
    padding-left: 15px;
    padding-right: 15px;
  }
  #banner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #cta {
    margin-bottom: 50px;
    padding-right: 0;
    flex-direction: row;
    justify-content: space-between;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  #cta span.el1, #cta span.el2 {
    width: 100%;
    text-align: left;
  }
  #cta span.el1 {
    font-size: 43px;
  }
  #cta span.el2 {
    margin-bottom: 12px;
  }
  #cta .shield {
    margin: 0;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 970px-------------------------------------------------------------- */
@media (max-width: 970px) {
  /* -- Homepage: Featured Content -- */
  #featured-content h3.widget-title:before, #featured-content h3.widget-title:after {
    width: 32%;
  }
  /* -- Homepage: Featured Content -- */
  #featured-content ul.menu li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40%;
    flex: 1 1 40%;
  }
}
@media screen and (max-width: 960px) {
  img {
    max-width: 100%;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 900px-------------------------------------------------------------- */
@media screen and (max-width: 900px) {
  #topWrapper {
    padding-top: 12px;
    padding-bottom: 16px;
  }
  #topWrapper div.sitetitle, #topWrapper div.phone {
    font-size: 20px;
    line-height: 24px;
  }
  #access a {
    font-size: 16px;
  }
  td.post_thumbnail {
    width: 20%;
  }
  #frmWrapper {
    padding: 24px 0;
  }
  #primary {
    width: 100%;
    margin-right: 0px;
  }
  #secondary {
    width: 100%;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 820px-------------------------------------------------------------- */
@media screen and (max-width: 820px) {
  #access a {
    font-size: 14px;
  }
  #cta {
    flex-direction: column;
  }
  #cta span.el1 {
    font-size: 60px;
    text-align: center;
  }
  #cta span.el2 {
    margin-bottom: 50px;
    text-align: center;
  }
  #cta .shield {
    margin: 0 auto;
    margin-bottom: 0px;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 770px-------------------------------------------------------------- */
@media screen and (max-width: 770px) {
  .breadcrumb {
    display: none;
  }
  #access {
    position: fixed;
    width: 100%;
    top: -700px;
    margin: 0px;
    padding: 0;
    z-index: 999;
  }
  #access div {
    margin: 0px;
  }
  #access ul li, .home #access ul li:first-child, #access ul li:first-child {
    width: 100%;
    border-bottom: 1px #CCC solid;
    text-align: left;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
  }
  #access ul li a {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    background: #1e1e1e;
    -webkit-transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  #access ul li a:hover {
    color: #fff;
    background: #242c65;
  }
  #mobile-banner-wrap {
    background-color: transparent !important;
    padding-bottom: 0;
  }
  #frmWrapper div.form-group {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  #frmWrapper div.form-group:nth-of-type(4) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  #frmWrapper button {
    width: 100%;
  }
  #topWrapperContainer {
    height: 80px;
  }
  #topWrapper {
    padding-top: 17px;
    padding-bottm: 18px;
  }
  #mobile-menu-toggle {
    display: block;
  }
  #topWrapper .phone {
    display: none;
  }
  .mobile_phone {
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    display: block;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }
  .mobile_phone a {
    color: #fff;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }
  /*#navigation{
  margin-top: 80px;
  }
  */
}
@media screen and (max-width: 770px) and (min-width: 768px) {
  #topWrapperContainer.scrolled .mobile_phone, #topWrapperContainer.scrolled .mobile_phone a {
    color: #0e1126;
  }
}
@media screen and (max-width: 770px) {
  .mobile_nav_show #access {
    top: 80px;
  }
  #colophon {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #colophon .colLeft {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-right: 0px;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    text-align: center;
  }
  #colophon .colMiddle {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-left: 0px;
    padding-right: 0px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    text-align: center;
  }
  #colophon .colRight {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-top: 0px;
    padding-left: 0px;
    margin-bottom: 24px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: center;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 768px;
-------------------------------------------------------------- */
@media (max-width: 768px) {
  /* -- Homepage: Need Assistance? -- */
  #need-assistance h2 {
    font-size: 26px;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 700px;
-------------------------------------------------------------- */
@media screen and (max-width: 700px) {
  /* -- Homepage: Featured Content -- */
  #featured-content ul.menu li {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 640px;
-------------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .leftNav, .contentBody {
    float: none;
    width: 98%;
  }
  .rightNav {
    float: none;
    width: 98%;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 600px;
-------------------------------------------------------------- */
@media screen and (max-width: 600px) {
  .home .homepage-intro h1 {
    font-size: 22px;
  }
  .home .homepage-intro p {
    font-size: 14px;
  }
  /* -- Homepage: Featured Content -- */
  #featured-content p {
    font-size: 14px;
  }
  /* -- Homepage: Need Assistance? -- */
  #need-assistance h2 {
    margin-right: 20px;
    border-top: 1px solid #333;
    padding-top: 40px;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 500px;
-------------------------------------------------------------- */
@media (max-width: 500px) {
  #cta span.el1 {
    font-size: 48px;
  }
  #cta span.el2 {
    font-size: 28px;
  }
  #cta span.el3 {
    font-size: 30px;
  }
  #cta span.el4 {
    font-size: 28px;
  }
  #cta .shield {
    padding-left: 120px;
  }
  #cta .shield a {
    width: 100px;
    height: 78px;
    top: -10px;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 440px;
-------------------------------------------------------------- */
@media (max-width: 440px) {
  #topWrapper div.sitetitle {
    font-size: 16px;
  }
  /* -- Homepage: Need Assistance -- */
  #need-assistance h2 {
    font-size: 22px;
  }
  /* ----------------------- Footer ----------------------- */
  #colophon .name {
    font-size: 22px;
  }
}
/*--------------------------------------------------------------Mobile Viewports;
Max 440px;
-------------------------------------------------------------- */
@media (max-width: 440px) {
  #topWrapper div.sitetitle {
    font-size: 14px;
  }
}
/* BEGIN CONTACT INFO BAR */
.contact-info-bar {
  display: flex;
  font-size: 1.25rem;
  background-color: #9D4140;
  text-align: center;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  padding: 0.25rem;
  gap: 0.25rem;
}

@media screen and (min-width: 768px) {
  .contact-info-bar {
    display: none;
  }
}
.contact-info-bar .call-button, .contact-info-bar .text-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #B54B4A;
  color: #fff;
  padding: 0.75rem 2rem;
  width: 100%;
  width: calc(50% - 0.125rem);
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.contact-info-bar .call-button .call-button-icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background: url("images/call.png");
  background-size: cover;
  background-position: center center;
  margin-right: 0.5rem;
  position: relative;
}

.contact-info-bar .text-button .text-button-icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background: url("images/text.png");
  background-size: cover;
  background-position: center center;
  margin-right: 0.5rem;
  position: relative;
}

/* END CONTACT INFO BAR */
